.description-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
    text-align: center;
    color: #ffffff;
    width: 100%;
    box-sizing: border-box;
  }
  
  .description-overlay h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .description-overlay p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  /* Responsive styles for mobile devices */
  @media screen and (max-width: 768px) {
    .description-overlay {
      max-width: 90%;
      padding: 15px;
      font-size: 0.9rem;
    }
  
    .description-overlay h1 {
      font-size: 1.5rem;
    }
  
    .description-overlay p {
      font-size: 1rem;
    }
  }
  